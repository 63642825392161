@import 'src/assets/sass/variables';

$hoverCheckbox: rgba($color-primary, 0.2);
$selectFieldClearIconPositionRight: 25px;
$selectFieldClearIconSize: 1.2em;
$selectFieldClearIconFontSize: 1.25rem;
$border: solid $border-thick $color-primary;

.MuiFormField {
  .MuiTextField-root,
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    text-overflow: ellipsis;
  }
  .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
    color: $color-primary;
  }
  .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
    border: $border;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &.MuiFormFieldPicker .MuiInputAdornment-root.MuiInputAdornment-positionEnd .MuiSvgIcon-root {
    color: $dark-gray;
  }
  &.MuiFormFieldFilled {
    fieldset legend {
      max-width: 1000px;
      transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }
    .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}
.MuiFormSelectField {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: $border;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
}

.MuiFormControlLabel {
  max-width: 100%;
  .MuiTypography-root,
  .MuiFormControlLabel-label,
  .MuiTypography-body1 {
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
  }
  .MuiCheckbox-colorPrimary.Mui-disabled {
    opacity: 0.8;
  }
  .MuiCheckbox-colorPrimary {
    margin-right: $grid-small;
  }
  .MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked .MuiSvgIcon-root {
    color: $color-primary;
    &:hover {
      background-color: $hoverCheckbox;
    }
  }

  .MuiCheckbox-root.MuiCheckbox-colorPrimary .MuiSvgIcon-root {
    color: $dark-gray;
  }
}

// datePicker modal styles
.MuiPickersBasePicker-container {
  .MuiButtonBase-root {
    outline: none;
  }
  .MuiPickersToolbar-toolbar {
    background-color: $color-primary;
    .MuiButtonBase-root {
      &:hover {
        background: transparent;
      }
    }
  }
  .MuiButtonBase-root.MuiPickersDay-daySelected {
    background-color: $color-primary;
  }
}

// TODO get rid of color definition by using xt-content .MuiAccordion-root .MuiIconButton-root in general-styles
.xt-content .MuiAccordion-root .MuiIconButton-root.MuiClearableFilterClearButton {
  color: $dark-gray;
}

.MuiFormFieldSelect {
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
    padding-right: $selectFieldClearIconPositionRight * 2;
  }
  .MuiClearableFilterClearButton {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: $selectFieldClearIconSize;
    height: $selectFieldClearIconSize;
    margin-right: $grid-tiny;
    svg {
      font-size: $selectFieldClearIconFontSize;
    }
    color: $dark-gray;
    &:focus {
      border-bottom: unset;
    }
  }
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    position: absolute;
    right: $selectFieldClearIconPositionRight;
  }
  &.MufFormSelectEmpty .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 20px) scale(1); // TODO find another way to prevent class overriding
  }
  &.MufFormSelectEmpty fieldset legend {
    max-width: 0.01px !important; // TODO find another way to prevent class overriding
  }

  &.MufFormSelectFilled .MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75); // TODO find another way to prevent class overriding
  }

  &.MufFormSelectFilled fieldset legend {
    max-width: 1000px !important; // TODO find another way to prevent class overriding
  }

  &:hover {
    .MuiClearableFilterClearButton {
      visibility: visible;
    }
  }
  &:not(:hover) {
    .MuiClearableFilterClearButton {
      visibility: hidden;
    }
  }
}
.MuiFormRadioGroup {
  .MuiRadio-root,
  .MuiRadio-colorPrimary.Mui-checked {
    color: $color-primary;
  }
  .MuiFormRadioGroupLabel {
    font-family: $font-family;
    font-size: $font-size-colossal;
  }
}

.MuiFormControl-root {
  height: fit-content;
}
