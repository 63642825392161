@import 'src/assets/sass/variables';

$marginPreviewDialog: $grid-base * 3;
$paddingPreviewDialog: $grid-base + $grid-medium;
$heightPreviewDialog: calc(100vh - #{$marginPreviewDialog * 2});
$previewImgMaxHeightMob: calc(#{$heightPreviewDialog} + #{$grid-base * 2});
$previewImgMaxHeight: calc(#{$heightPreviewDialog} - #{$marginPreviewDialog});

$closeIconSize: 16px;

.documentsContent {
  display: grid;
  grid-template-rows: auto $xtTableHeight;
  grid-gap: $grid-base;
}

.listItem {
  display: flex;
  align-items: center;
}

.listItemName {
  flex: 1 1 auto;
  margin: 0.5em;
}

.listItemActions {
  flex: 0 0 auto;
}

.listItemContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.listItemWrapper {
  display: block;
  margin: $grid-tiny 0;
}

.listWrapper {
  background-color: white;
  height: $xtTableHeight;
}

.noDataAvailableMessage {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentsList {
  width: 100%;
  @media screen and (max-width: $bp-xs) {
    overflow-y: auto;
  }
}

.documentPreviewImg {
  max-width: 100%;
  object-fit: contain;
  @media screen and (min-width: $bp-sm) {
    max-height: $previewImgMaxHeight;
  }
  height: $previewImgMaxHeightMob;
}

.documentPreviewDialogRoot {
  .documentPreviewDialogContainer {
    outline: none;
  }
  @media screen and (min-width: $bp-sm) {
    height: $heightPreviewDialog;
    padding: $paddingPreviewDialog;
    margin: $marginPreviewDialog;
    border-radius: $grid-small;
  }
  background-color: $black-hr;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $paddingPreviewDialog 0;

  .closeIcon {
    position: absolute;
    top: $grid-small;
    right: $grid-small;
    color: $white-color;

    .closeIconSize {
      width: $closeIconSize;
      height: $closeIconSize;
    }
  }

  .documentPreviewLoadingSpinner {
    background-color: transparent;

    *,
    & {
      color: $white-color;
    }
  }
}
