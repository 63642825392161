@import 'src/assets/sass/variables';

.characteristicsAddButton {
  justify-self: end;
}

.characteristics {
  display: grid;
  grid-template-rows: auto $xtTableHeight;
  grid-gap: $grid-base;
}

.characteristicsList {
  overflow: hidden;
  position: relative;
  @media screen and (max-width: $bp-xs) {
    overflow: auto;
  }
}
