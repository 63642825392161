@import 'src/assets/sass/variables';
@import 'src/assets/sass/common-styles';

$scrollbarSize: 7px;
$xtGridRowBorder: 1px solid rgba(0, 0, 0, 0.15);
$xtGridRowHeight: 52px;
$xtGridCellPadding: 0 15px;
$xtGridTableHeaderZIndex: 1;
$overlayZIndex: 100;

.xt-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .xt-table-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    @extend .xt-scrollbar-style;
  }

  .xt-table-root-element {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    background-color: $white-color;

    .xt-table-empty-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      font-size: $font-size-large;
    }

    .xt-table-header {
      position: sticky;
      position: -webkit-sticky;
      z-index: $xtGridTableHeaderZIndex;
      top: 0;
      display: block;
    }

    .xt-table-header-cell {
      display: block;
      font-weight: $font-weight-bold;
      font-size: $font-size-huge;
    }

    .xt-table-row {
      background-color: $white-color;
      display: flex;
      border-bottom: $xtGridRowBorder;
      min-height: $xtGridRowHeight;
      height: $xtGridRowHeight;
      align-items: center;
      margin-left: 0;
      padding-left: $grid-base;
      flex-wrap: nowrap;
      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      }
    }

    .xt-table-row--selected {
      background: $color-gray-normal;
    }

    .xt-table-cell {
      font-family: $font-family;
      border-bottom: none;
      padding: $xtGridCellPadding;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .xt-table-cell-body {
      display: block;
      font-weight: $font-weight-light;
      font-size: $font-size-large;
    }

    .xt-table-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .xt-table-pagination {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    overflow: initial;
    background-color: $white-color;
    .MuiTablePagination-selectRoot,
    .MuiTablePagination-select {
      width: auto;
    }
  }
}

.xt-table-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $overlayZIndex;
}
