@import 'src/assets/sass/variables';

.textArea {
  width: 100%;
  border: $border-grey-primary;
  font-family: $font-family;
  font-size: $font-size-medium;
  outline: none;
  resize: none;
  font-weight: $font-weight-normal;
  padding: $grid-medium;
  background-color: $white-color;
}
