@import 'src/assets/sass/variables';

a.activeLink {
  border-bottom: $border-black;
}

a.navContainer {
  display: flex;
  align-items: center;
  padding: 0 $grid-small * 2;
  cursor: pointer;
  min-width: unset;

  font-family: $font-family;
  text-decoration: none;
  text-transform: unset;
  color: $black-color;
  font-weight: $font-weight-light;
  user-select: none;
  font-size: $font-size-colossal;
  &:hover {
    border-bottom: $border-black;
  }
}

.tabsContainer {
  align-items: center;
  height: 100%;
}

a.tabTextInherit {
  opacity: 1;
  letter-spacing: unset;
}
