@import 'src/assets/sass/variables';

$accordionSummaryHeight: 45px;
$darkThemeBackground: rgba(8, 104, 172, 0.54);

$iconSize: 15px;

.xtAccordion {
  .titleSummaryContent {
    margin: 0;
  }

  &.xtAccordionInvalid {
    &.muiAccordion {
      .muiAccordionSummary {
        background-color: $color-orange;
        color: $white-color;
        .muiExpandIcon {
          color: $white-color;
        }
      }
    }

    &.muiAccordionDark {
      .muiAccordionSummary {
        background-color: $color-orange;
      }
    }
  }

  &.muiAccordion {
    &::before {
      display: none;
    }
    background-color: transparent;
    .muiAccordionSummary {
      background-color: $white-color;
      color: $black-hr;
      max-height: $accordionSummaryHeight;
      min-height: $accordionSummaryHeight;
      border-radius: $border-radius-large;
      border: $border-grey;

      .muiExpandIcon {
        color: $black-hr;
      }

      .muiAccordionSummaryContent {
        padding: 0;
        margin: 0;
        max-height: $accordionSummaryHeight;
        background-color: $white-color;
        color: $black-hr;
      }
    }

    .muiAccordionSummaryExpanded {
      max-height: $accordionSummaryHeight;
      min-height: $accordionSummaryHeight;
      display: flex;
      align-items: center;
      border-radius: $border-radius-large;
      .muiAccordionSummaryContent {
        margin: 0;
        padding: 0;
        background-color: transparent;
      }
    }
  }

  &.muiAccordionDark {
    .muiAccordionSummary {
      background-color: $darkThemeBackground;
      color: $white-color;

      &.muiAccordionInvalid {
        background-color: $color-orange;
      }
    }

    .muiExpandIcon {
      color: $white-color;
    }
  }

  .muiExpandIconSize {
    height: $iconSize;
    width: $iconSize;
  }

  .muiAccordionDetails {
    margin: 0;
  }

  &.muiAccordionMargin.muiAccordion {
    margin: 0;
  }
}
